import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";

function About() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} sx={{ margin: "0.3rem", padding: "1vw" }}>
        <Grid size="grow">
          <Typography gutterBottom variant="h5" component="div">
            My Blog
          </Typography>
          <Typography gutterBottom variant="h8" component="div">
            I am happy to introduce my cooking blog, where I share my favorite
            recipes and cooking tricks. Here you will find delicious and healthy
            dishes for every taste, from classic to modern interpretations.
            Enjoy culinary masterpieces with me!
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default About;
