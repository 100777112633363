import { useEffect, useContext, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { AuthContext } from "../contexts/AuthContext.js";
import config from "../config/config.json";

const BACKEND_DOMAIN = config.site.auth.urls.backend;

const POSTDETAIL_URL = `${BACKEND_DOMAIN}/${config.site.blog.urls.postdetail}`;

const DeletePost = () => {
  const { auth } = useContext(AuthContext);
  const [message, setMessage] = useState();

  const { slug } = useParams();
  const navigate = useNavigate();

  const deletePost = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.access}`,
      },
    };
    try {
      const response = await axios.delete(`${POSTDETAIL_URL}${slug}/`, config);
      setMessage({ error: false, message: response });
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    deletePost();
    navigate("/");
  };

  return (
    <Stack>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "1vh" }}
      >
        <Box>Do you want to Delete post?</Box>
        <Button
          variant="contained"
          sx={{ my: 2, color: "white", alignSelf: "center" }}
          onClick={() => navigate(-1)}
        >
          No
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ my: 2, color: "white", alignSelf: "center" }}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Grid>
    </Stack>
  );
};

export default DeletePost;
