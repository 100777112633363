import { useState, useEffect, useContext } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import axios from "axios";
import { BlogContext } from "../../contexts/BlogContext.js";
import config from "../../config/config.json";

const BACKEND_DOMAIN = config.site.auth.urls.backend;

const TAGS_URL = `${BACKEND_DOMAIN}/${config.site.blog.urls.tags}`;

function TagList() {
  const { selectedTags, setSelectedTags } = useContext(BlogContext);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState();

  const pageParams = "limit=100&offset=0";

  const loadTags = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get(`${TAGS_URL}?${pageParams}`, config);
      setTags(response.data.results);
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
      setLoading(false);
    }
  };

  const addTag = (tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags((prev) => [...prev, tag]);
    }
  };

  const removeTag = (tag) => {
    if (selectedTags.includes(tag)) {
      const removedList = selectedTags.filter((item) => item !== tag);
      setSelectedTags(removedList);
    }
  };

  const resetTag = () => {
    setSelectedTags([]);
  };

  const tagToggle = (name) => {
    if (selectedTags.includes(name)) {
      removeTag(name);
    } else {
      addTag(name);
    }
  };

  useEffect(() => {
    loadTags();
  }, []);

  if (loading) return <>Loading</>;

  const tagsList = tags?.map((tag) => (
    <Button
      key={tag.id}
      onClick={() => tagToggle(tag.slug)}
      variant={selectedTags.includes(tag.slug) ? "contained" : "outlined"}
      size="small"
    >
      {tag.name}
    </Button>
  ));

  return (
    <Stack
      spacing={2}
      direction="row"
      useFlexGap
      sx={{
        justifyContent: "center",
        alignItems: "baseline",
        paddingTop: "1vh",
        flexWrap: "wrap",
        overflow: "auto",
      }}
    >
      {tagsList}
      <Button onClick={() => resetTag()} variant="outlined" color="error">
        Clear
      </Button>
    </Stack>
  );
}

export default TagList;
