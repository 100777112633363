import { useContext, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { AuthContext } from "../contexts/AuthContext.js";

const Activate = () => {
  const { setAuthData, activationMessage, setActivationMessage } =
    useContext(AuthContext);

  const { uid, token } = useParams();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setActivationMessage("");
  }, []);

  const handleActivate = (e) => {
    e.preventDefault();

    const userData = {
      type: "activation",
      data: { uid, token },
    };
    setAuthData(userData);
  };

  return (
    <Container maxWidth="sm">
      <Stack>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ margin: "1vh" }}
        >
          <Box>Do you want to Activate account?</Box>
          <Button
            variant="contained"
            sx={{ my: 2, color: "white", alignSelf: "center" }}
            onClick={handleActivate}
          >
            Activate
          </Button>
        </Grid>
        {activationMessage && (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Alert severity={activationMessage.error ? "error" : "success"}>
              {activationMessage?.message}
            </Alert>
          </Grid>
        )}
      </Stack>
    </Container>
  );
};

export default Activate;
