import { useEffect, useState, useContext, useLayoutEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { AuthContext } from "../contexts/AuthContext.js";

const Register = () => {
  const { loading, setAuthData, registerMessage, setRegisterMessage } =
    useContext(AuthContext);
  const [localLoading, setLocalLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setLocalLoading(loading);
  }, [loading]);

  useLayoutEffect(() => {
    setLocalLoading(loading);
    setRegisterMessage("");
  }, []);

  const validateInputs = ({
    email,
    password,
    first_name,
    last_name,
    re_password,
  }) => {
    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      if (password !== re_password) {
        setPasswordError(true);
        setPasswordErrorMessage("Password must be equil to second Password.");
        isValid = false;
      } else {
        setPasswordError(false);
        setPasswordErrorMessage("");
      }
    }

    if (!first_name || first_name.length < 1) {
      setFirstNameError(true);
      setFirstNameErrorMessage("First name is required.");
      isValid = false;
    } else {
      setFirstNameError(false);
      setFirstNameErrorMessage("");
    }

    if (!last_name || last_name.length < 1) {
      setLastNameError(true);
      setLastNameErrorMessage("First name is required.");
      isValid = false;
    } else {
      setLastNameError(false);
      setLastNameErrorMessage("");
    }

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append("type", "register");
    const formData = Object.fromEntries(data);
    if (validateInputs(formData)) {
      setLocalLoading(true);
      setAuthData(formData);
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{ paddingTop: "2vh" }}
    >
      <Container maxWidth="sm">
        <Card variant="outlined" sx={{ padding: "1vh" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
            >
              Register
            </Typography>
            {registerMessage && (
              <Alert severity={registerMessage.error ? "error" : "success"}>
                {registerMessage.error ? "Error" : "OK! Wait for email!"}
              </Alert>
            )}
          </Stack>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                autoFocus
                required
                fullWidth
                id="email"
                placeholder="your@email.com"
                name="email"
                autoComplete="email"
                variant="outlined"
                error={emailError}
                helperText={emailErrorMessage}
                color={passwordError ? "error" : "primary"}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="first_name">First name</FormLabel>
              <TextField
                autoComplete="first_name"
                name="first_name"
                required
                fullWidth
                id="first_name"
                placeholder="First Name"
                error={firstNameError}
                helperText={firstNameErrorMessage}
                color={firstNameError ? "error" : "primary"}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="last_name">Last name</FormLabel>
              <TextField
                autoComplete="last_name"
                name="last_name"
                required
                fullWidth
                id="last_name"
                placeholder="Last Name"
                error={lastNameError}
                helperText={lastNameErrorMessage}
                color={lastNameError ? "error" : "primary"}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                required
                fullWidth
                name="password"
                placeholder="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                variant="outlined"
                error={passwordError}
                helperText={passwordErrorMessage}
                color={passwordError ? "error" : "primary"}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="re_password">Retype Password</FormLabel>
              <TextField
                required
                fullWidth
                name="re_password"
                placeholder="Retype Password"
                type="password"
                id="re_password"
                autoComplete="new-password"
                variant="outlined"
                error={passwordError}
                helperText={passwordErrorMessage}
                color={passwordError ? "error" : "primary"}
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive updates via email."
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              onClick={validateInputs}
              loading={localLoading}
            >
              Register
            </LoadingButton>
            <Typography sx={{ textAlign: "center" }}>
              Already have an account?{" "}
              <span>
                <NavLink to="/login">
                  <Button
                    variant="contained"
                    sx={{ my: 2, color: "white", alignSelf: "center" }}
                  >
                    Login
                  </Button>
                </NavLink>
              </span>
            </Typography>
          </Box>
        </Card>
      </Container>
    </Stack>
  );
};

export default Register;
