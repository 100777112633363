import { createContext, useState } from "react";

export const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  return (
    <BlogContext.Provider
      value={{
        selectedTags,
        setSelectedTags,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};
