import { useContext, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { AuthContext } from "../../contexts/AuthContext.js";

function ChangePassword() {
  const {
    loading,
    isAuth,
    user,
    setAuthData,
    passwordUserMessage,
    setPasswordUserMessage,
  } = useContext(AuthContext);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const handleSetPassword = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append("type", "setpassword");
    const formData = Object.fromEntries(data);
    setPasswordUserMessage("");
    if (validateInputs(formData)) setAuthData(formData);
  };

  const validateInputs = ({ new_password, re_new_password }) => {
    let isValid = true;
    if (!new_password || new_password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      if (new_password !== re_new_password) {
        setPasswordError(true);
        setPasswordErrorMessage("Password must be equil to second Password.");
        isValid = false;
      } else {
        setPasswordError(false);
        setPasswordErrorMessage("");
      }
    }
    return isValid;
  };

  if (loading) return <>Loading</>;

  return (
    <Card variant="outlined" sx={{ padding: "1vh" }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          component="h1"
          variant="h4"
          sx={{ fontSize: "clamp(1.5rem, 7vw, 1.65rem)" }}
        >
          Set password
        </Typography>
        {passwordUserMessage && (
          <Alert severity={passwordUserMessage?.error ? "error" : "success"}>
            {passwordUserMessage?.message}
          </Alert>
        )}
      </Stack>
      <Box
        component="form"
        onSubmit={handleSetPassword}
        noValidate
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="current_password">Current Password</FormLabel>
          <TextField
            required
            fullWidth
            name="current_password"
            placeholder="Current Password"
            type="password"
            id="current_password"
            autoComplete="new-password"
            variant="outlined"
            error={passwordError}
            helperText={passwordErrorMessage}
            color={passwordError ? "error" : "primary"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="new_password">Password</FormLabel>
          <TextField
            required
            fullWidth
            name="new_password"
            placeholder="New Password"
            type="password"
            id="new_password"
            autoComplete="new-password"
            variant="outlined"
            error={passwordError}
            helperText={passwordErrorMessage}
            color={passwordError ? "error" : "primary"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="re_new_password">Retype Password</FormLabel>
          <TextField
            required
            fullWidth
            name="re_new_password"
            placeholder="Retype New Password"
            type="password"
            id="re_new_password"
            autoComplete="new-password"
            variant="outlined"
            error={passwordError}
            helperText={passwordErrorMessage}
            color={passwordError ? "error" : "primary"}
          />
        </FormControl>
        <Button type="submit" fullWidth variant="contained">
          Set password
        </Button>
      </Box>
    </Card>
  );
}

export default ChangePassword;
