import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import { BlogContext } from "../contexts/BlogContext.js";
import TagList from "./Posts/TagList.js";
import PostList from "./Posts/PostList.js";

function Posts() {
  return (
    <Container maxWidth="xl">
      <Box>
        <Stack spacing={2}>
          <TagList />
          <PostList />
        </Stack>
      </Box>
    </Container>
  );
}

export default Posts;
