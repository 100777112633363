import { createContext, useState, useEffect, useReducer } from "react";
import axios from "axios";
import config from "../config/config.json";

const BACKEND_DOMAIN = config.site.auth.urls.backend;

const REGISTER_URL = `${BACKEND_DOMAIN}/${config.site.auth.urls.register}`;
const LOGIN_URL = `${BACKEND_DOMAIN}/${config.site.auth.urls.login}`;
const ACTIVATE_URL = `${BACKEND_DOMAIN}/${config.site.auth.urls.activation}`;
const RESET_PASSWORD_URL = `${BACKEND_DOMAIN}/${config.site.auth.urls.reset_password}`;
const RESET_PASSWORD_CONFIRM_URL = `${BACKEND_DOMAIN}/${config.site.auth.urls.reset_password_confirm}`;
const GET_USER_INFO = `${BACKEND_DOMAIN}/${config.site.auth.urls.user_info}`;
const GET_USER_INFO_FULL = `${BACKEND_DOMAIN}/${config.site.auth.urls.user_info_full}`;
const LOGIN_VERIFY_URL = `${BACKEND_DOMAIN}/${config.site.auth.urls.login_verify}`;
const SET_PASSWORD_URL = `${BACKEND_DOMAIN}/${config.site.auth.urls.set_password}`;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authData, setAuthData] = useState(0);
  const [auth, setAuth] = useState({ access: null, refresh: null });
  const [user, setUser] = useState({});
  const [isAuth, setIsAuth] = useState(false);
  const [message, setMessage] = useState(0);
  const [loginMessage, setLoginMessage] = useState("");
  const [registerMessage, setRegisterMessage] = useState("");
  const [activationMessage, setActivationMessage] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [passwordUserMessage, setPasswordUserMessage] = useState("");
  const [isAuthFail, setIsAuthFail] = useState(false);
  const [updateUserLoading, setUpdateUserLoading] = useState(false);

  const resetToDefault = () => {
    setLoading(false);
    setAuthData(0);
    setAuth({ access: null, refresh: null });
    setUser({});
    setIsAuth(false);
    setIsAuthFail(false);
  };

  const checkAuthenticated = async () => {
    if (typeof window == "undefined") {
      setIsAuthFail(true);
    }
    if (auth.access) {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({ token: auth.access });
      try {
        const res = await axios.post(LOGIN_VERIFY_URL, body, config);
        if (res.data.code !== "token_not_valid") {
          loadUser();
        } else {
          setIsAuthFail(true);
        }
      } catch (err) {
        setMessage({ error: true, message: err });
        setIsAuthFail(true);
      }
    } else {
      setIsAuthFail(true);
    }
  };

  const loadUserFull = async (userid) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.access}`,
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get(
        `${GET_USER_INFO_FULL}${userid}/`,
        config,
      );
      setUser(response.data);
      setIsAuth(true);
      setMessage({ error: false, message: response });
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
      setLoading(false);
    }
  };

  const loadUser = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.access}`,
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get(GET_USER_INFO, config);
      loadUserFull(response.data.id);
      setMessage({ error: false, message: response });
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
    }
  };

  const loginUser = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(LOGIN_URL, authData, config);
      setAuth(response.data);
    } catch (err) {
      setMessage({ error: true, message: err });
      setLoginMessage({
        error: true,
        message: err.response.data.detail,
      });
      setLoading(false);
    } finally {
      setAuthData(0);
    }
  };

  const resetUserPassword = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        RESET_PASSWORD_URL,
        { email: authData.email },
        config,
      );
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
      setAuthData(0);
    }
  };

  const registerUser = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(REGISTER_URL, authData, config);
      setMessage({ error: false, message: response });
      setRegisterMessage({ error: false, message: response.statusText });
    } catch (err) {
      setMessage({ error: true, message: err });
      setRegisterMessage({ error: true, message: err.response.data });
    } finally {
      setLoading(false);
      setAuthData(0);
    }
  };

  const activateUser = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(ACTIVATE_URL, authData.data, config);
      setMessage({ error: false, message: response });
      setActivationMessage({
        error: false,
        message: "User activated",
      });
    } catch (err) {
      setMessage({ error: true, message: err });
      setActivationMessage({
        error: true,
        message: err.response.data.detail,
      });
    } finally {
      setAuthData(0);
    }
  };

  const resetPasswordConfirmUser = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        RESET_PASSWORD_CONFIRM_URL,
        authData.data,
        config,
      );
      setMessage({ error: false, message: response });
      setResetMessage({
        error: false,
        message: "Password reseted",
      });
    } catch (err) {
      setMessage({ error: true, message: err });
      setResetMessage({
        error: true,
        message: "Error",
      });
    } finally {
      setAuthData(0);
    }
  };

  const setPasswordUser = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.access}`,
      },
    };
    try {
      const response = await axios.post(SET_PASSWORD_URL, authData, config);
      setPasswordUserMessage({ error: false, message: "OK" });
      setMessage({ error: false, message: response });
    } catch (err) {
      setPasswordUserMessage({ error: true, message: "Error" });
      setMessage({ error: true, message: err });
    } finally {
      setAuthData(0);
    }
  };

  const updateUser = async () => {
    console.log("updateUser");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${auth.access}`,
      },
    };
    try {
      const response = await axios.patch(GET_USER_INFO, authData.data, config);
      setMessage({ error: false, message: response });
      loadUserFull(user.id);
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
      setUpdateUserLoading(false);
      setAuthData(0);
    }
  };

  useEffect(() => {
    if (isAuth) {
      localStorage.setItem("access", auth.access);
      localStorage.setItem("refresh", auth.refresh);
    }
  }, [isAuth]);

  useEffect(() => {
    if (auth.access) {
      checkAuthenticated();
    }
  }, [auth]);

  useEffect(() => {
    const access = localStorage.getItem("access");
    const refresh = localStorage.getItem("refresh");
    if (access) {
      setAuth({
        access: access,
        refresh: refresh,
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (authData) {
      switch (authData?.type) {
        case "login":
          if (authData.email && authData.password) {
            setLoading(true);
            loginUser();
          }
          break;
        case "logout":
          resetToDefault();
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          setMessage("");
          break;
        case "resetPassword":
          resetUserPassword();
          break;
        case "register":
          setLoading(true);
          registerUser();
          break;
        case "activation":
          activateUser();
          break;
        case "passwordresetconfirm":
          resetPasswordConfirmUser();
          break;
        case "setpassword":
          setPasswordUser();
          break;
        case "updateuser":
          setUpdateUserLoading(true);
          updateUser();
          break;
        default:
          setAuthData(0);
      }
    }
  }, [authData]);

  useEffect(() => {
    if (isAuthFail) {
      resetToDefault();
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
    }
  }, [isAuthFail]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        setLoading,
        setAuthData,
        isAuth,
        user,
        auth,
        loginMessage,
        setLoginMessage,
        registerMessage,
        setRegisterMessage,
        activationMessage,
        setActivationMessage,
        resetMessage,
        setResetMessage,
        updateUserLoading,
        passwordUserMessage,
        setPasswordUserMessage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
