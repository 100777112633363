import { useContext, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Divider from "@mui/material/Divider";
import ForgotPassword from "./ForgotPassword";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { AuthContext } from "../contexts/AuthContext.js";
import { BlogContext } from "../contexts/BlogContext.js";
import config from "../config/config.json";

const BACKEND_DOMAIN = config.site.auth.urls.backend;

const POSTDETAIL_URL = `${BACKEND_DOMAIN}/${config.site.blog.urls.postdetail}`;

function EditPost() {
  const { auth, user, loading } = useContext(AuthContext);
  const formRef = useRef();
  const [post, setPost] = useState({});
  const [localLoading, setLocalLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [message, setMessage] = useState();
  const navigate = useNavigate();

  const { slug } = useParams();

  const postUpload = async (data) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${auth.access}`,
      },
    };
    try {
      const response = edit
        ? await axios.put(`${POSTDETAIL_URL}${slug}/`, data, config)
        : await axios.post(POSTDETAIL_URL, data, config);
      setMessage({ error: false, message: response });
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
      setLocalLoading(false);
      if (slug === "new") navigate(`/post/${data.get("slug")}`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLocalLoading(true);
    const data = new FormData(event.currentTarget);
    if (edit) {
      data.append("author", post.author);
    } else {
      data.append("author", user.id);
    }
    if (validateInputs(data)) {
      postUpload(data);
    }
    if (slug !== "new") navigate(`/post/${slug}`);
  };

  const validateInputs = () => {
    let isValid = true;

    return isValid;
  };

  const loadPost = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get(`${POSTDETAIL_URL}${slug}/`, config);
      setPost(response.data);
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
      setPostLoading(false);
    }
  };

  useEffect(() => {
    if (slug !== "new" && !post?.id) {
      loadPost();
      setEdit(true);
    } else {
      setPostLoading(false);
      setPost({});
      setEdit(false);
    }
  }, [slug]);

  if (postLoading) return <>Loading</>;

  return (
    <Container maxWidth="xl">
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{ paddingTop: "2vh" }}
      >
        <Card variant="outlined" sx={{ padding: "1vh" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
            >
              {edit ? "Edit " : "Create New "}Post
            </Typography>
          </Stack>
          <Box
            component="form"
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="title">Title</FormLabel>
              <TextField
                id="title"
                type="title"
                name="title"
                placeholder="You title"
                autoComplete="title"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ ariaLabel: "title" }}
                defaultValue={edit ? post?.title : ""}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="image">Title image</FormLabel>
              <TextField
                id="image"
                type="file"
                name="image"
                placeholder="Post image"
                autoComplete="image"
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ ariaLabel: "image" }}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="tags">Tags</FormLabel>
              <TextField
                id="tags"
                type="tags"
                name="tags"
                placeholder="Post tags"
                autoComplete="tags"
                required
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ ariaLabel: "tags" }}
                defaultValue={edit ? post?.tags : ""}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="slug">Slug</FormLabel>
              <TextField
                id="slug"
                type="slug"
                name="slug"
                placeholder="You slug"
                autoComplete="slug"
                required
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ ariaLabel: "slug" }}
                defaultValue={edit ? post?.slug : ""}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="description">Description</FormLabel>
              <TextField
                id="description"
                type="description"
                name="description"
                placeholder="Post description"
                autoComplete="description"
                fullWidth
                multiline
                variant="outlined"
                color="primary"
                sx={{ ariaLabel: "description" }}
                defaultValue={edit ? post?.description : ""}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="body">Text</FormLabel>
              <TextField
                id="body"
                type="body"
                name="body"
                placeholder="Post text"
                autoComplete="body"
                required
                multiline
                minRows={4}
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ ariaLabel: "body" }}
                defaultValue={edit ? post?.body : ""}
              />
            </FormControl>
            <FormControl>
              <InputLabel id="status">Status</InputLabel>
              <Select
                labelId="status"
                id="status"
                name="status"
                label="Status"
                defaultValue={edit ? post?.status : "DF"}
              >
                <MenuItem value={"DF"}>Draft</MenuItem>
                <MenuItem value={"PB"}>Published</MenuItem>
              </Select>
            </FormControl>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={localLoading}
            >
              Post
            </LoadingButton>
          </Box>
        </Card>
      </Stack>
    </Container>
  );
}

export default EditPost;
