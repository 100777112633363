import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login.js";
import Logout from "./pages/Logout.js";
import Register from "./pages/Register.js";
import Activate from "./pages/Activate.js";
import Main from "./pages/Main.js";
import Posts from "./pages/Posts.js";
import PostDetail from "./pages/PostDetail.js";
import About from "./pages/About.js";
import Profile from "./pages/Profile.js";
import EditPost from "./pages/EditPost.js";
import DeletePost from "./pages/DeletePost.js";
import PasswordResetConfirm from "./pages/PasswordResetConfirm.js";
import { AuthProvider } from "./contexts/AuthContext.js";
import { BlogProvider } from "./contexts/BlogContext.js";

function App() {
  return (
    <AuthProvider>
      <BlogProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />}>
              <Route index element={<Posts />}></Route>
              <Route path="post/:slug" element={<PostDetail />}></Route>
              <Route path="editpost/:slug" element={<EditPost />}></Route>
              <Route path="deletepost/:slug" element={<DeletePost />}></Route>
              <Route path="profile" element={<Profile />}></Route>
              <Route path="about" element={<About />}></Route>
              <Route path="login" element={<Login />}></Route>
              <Route path="logout" element={<Logout />}></Route>
              <Route path="register" element={<Register />}></Route>
              <Route path="activate/:uid/:token" element={<Activate />}></Route>
              <Route path="password/reset/confirm/:uid/:token" element={<PasswordResetConfirm />}></Route>
              <Route path="*" element={<h1>Not Found</h1>}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </BlogProvider>
    </AuthProvider>
  );
}

export default App;
