import { Outlet } from "react-router-dom";
import Container from "@mui/material/Container";
import MyNav from "./MyNav.js";

function Main() {
  return (
    <Container>
      <MyNav />
      <Outlet />
    </Container>
  );
}

export default Main;
