import { useEffect, useState, useContext, useLayoutEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid2";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { AuthContext } from "../../contexts/AuthContext.js";

const EditUser = () => {
  const { loading, setAuthData, user, updateUserLoading } =
    useContext(AuthContext);
  const [localLoading, setLocalLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLocalLoading(loading);
  }, [updateUserLoading]);

  const validateInputs = () => {
    let isValid = true;

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = { type: "updateuser", data: data };
    if (validateInputs) {
      setLocalLoading(true);
      setAuthData(formData);
    }
  };

  useEffect(() => {
    setLocalLoading(updateUserLoading);
  }, [updateUserLoading]);

  return (
    <Card variant="outlined" sx={{ padding: "1vh" }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          component="h1"
          variant="h4"
          sx={{ fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
        >
          Change
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="first_name">First name</FormLabel>
          <TextField
            autoComplete="first_name"
            name="first_name"
            required
            fullWidth
            id="first_name"
            placeholder="First Name"
            color="primary"
            defaultValue={user.first_name}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="last_name">Last name</FormLabel>
          <TextField
            autoComplete="last_name"
            name="last_name"
            required
            fullWidth
            id="last_name"
            placeholder="Last Name"
            color="primary"
            defaultValue={user.last_name}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="image">Avatar image</FormLabel>
          <TextField
            id="image"
            type="file"
            name="image"
            placeholder="Avatar image"
            autoComplete="image"
            autoFocus
            fullWidth
            variant="outlined"
            color="primary"
            sx={{ ariaLabel: "image" }}
          />
        </FormControl>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          onClick={validateInputs}
          loading={localLoading}
        >
          Change
        </LoadingButton>
      </Box>
    </Card>
  );
};

export default EditUser;
