import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AuthContext } from "../contexts/AuthContext.js";
import config from "../config/config.json";

const Logout = () => {
  const { setAuthData, isAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth)
      navigate(config.site.auth.redirect.after_logout);
  }, [isAuth]);

  const handleLogout = (e) => {
    e.preventDefault();
    setAuthData({type: "logout"});
  };

  return (
    <Box>
      <Typography sx={{ textAlign: "center" }}>
        Do you want to logout?{" "}
        <span>
          <Button
            variant="contained"
            sx={{ my: 2, color: "white", alignSelf: "center" }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </span>
      </Typography>
    </Box>
  );
};

export default Logout;
