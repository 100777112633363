import { useEffect, useState, useContext, useLayoutEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ForgotPassword from "./ForgotPassword";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { AuthContext } from "../contexts/AuthContext.js";
import config from "../config/config.json";

const Login = () => {
  const { loading, setAuthData, isAuth, loginMessage, setLoginMessage } =
    useContext(AuthContext);
  const [localLoading, setLocalLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLocalLoading(loading);
    if (!loading) if (isAuth) navigate(config.site.auth.redirect.after_login);
  }, [loading]);

  useLayoutEffect(() => {
    setLoginMessage("");
    setLocalLoading(loading);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append("type", "login");
    const formData = Object.fromEntries(data);
    setLoginMessage("");
    if (formData.email && formData.password) {
      if (validateInputs(formData)) {
        setLocalLoading(true);
        setAuthData(formData);
      }
    }
  };

  const validateInputs = ({ email, password }) => {
    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password || password.length < 4) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 4 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{ paddingTop: "2vh" }}
    >
      <Container maxWidth="sm">
        <Card variant="outlined" sx={{ padding: "1vh" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
            >
              Login
            </Typography>
            {loginMessage && (
              <Alert severity={loginMessage.error ? "error" : "success"}>
                {loginMessage?.message}
              </Alert>
            )}
          </Stack>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                id="email"
                type="email"
                name="email"
                placeholder="your@email.com"
                autoComplete="email"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={emailError ? "error" : "primary"}
                sx={{ ariaLabel: "email" }}
              />
            </FormControl>
            <FormControl>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Link
                  component="button"
                  onClick={handleClickOpen}
                  variant="body2"
                  sx={{ alignSelf: "baseline" }}
                >
                  Forgot your password?
                </Link>
              </Box>
              <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                required
                fullWidth
                variant="outlined"
                color={passwordError ? "error" : "primary"}
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <ForgotPassword open={open} handleClose={handleClose} />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={localLoading}
            >
              Login
            </LoadingButton>
            <Typography sx={{ textAlign: "center" }}>
              Don&apos;t have an account?{" "}
              <span>
                <NavLink to="/register">
                  <Button
                    variant="contained"
                    sx={{ my: 2, color: "white", alignSelf: "center" }}
                  >
                    Register
                  </Button>
                </NavLink>
              </span>
            </Typography>
          </Box>
        </Card>
      </Container>
    </Stack>
  );
};

export default Login;
