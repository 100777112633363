import { useContext, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ChangePassword from "./Profile/ChangePassword.js";
import EditUser from "./Profile/EditUser.js";
import { AuthContext } from "../contexts/AuthContext.js";

function Profile() {
  const { loading, isAuth, user } = useContext(AuthContext);
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) return <>Loading</>;

  return (
    <Container maxWidth="md">
      <Stack direction="column" justifyContent="space-between">
        <Box>
          <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
            Welcome,{" "}
            {isAuth ? user.first_name + " " + user.last_name : "Anonimous"}
          </Typography>
        </Box>
        <Container maxWidth="sm">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="Profile settings">
                  <Tab label="Change Password" value="1" />
                  <Tab label="Edit User" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <ChangePassword />
              </TabPanel>
              <TabPanel value="2">
                <EditUser />
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
      </Stack>
    </Container>
  );
}

export default Profile;
