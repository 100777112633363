import { useContext, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { AuthContext } from "../contexts/AuthContext.js";

const PasswordResetConfirm = () => {
  const { setAuthData, resetMessage, setResetMessage } =
    useContext(AuthContext);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const { uid, token } = useParams();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setResetMessage("");
  }, []);

  const handleReset = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append("uid", uid);
    data.append("token", token);
    const formData = Object.fromEntries(data);
    const userData = { type: "passwordresetconfirm", data: data };
    if (validateInputs(formData)) {
      setAuthData(userData);
    }
  };

  const validateInputs = ({ new_password, re_new_password }) => {
    let isValid = true;
    if (!new_password || new_password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      if (new_password !== re_new_password) {
        setPasswordError(true);
        setPasswordErrorMessage("Password must be equil to second Password.");
        isValid = false;
      } else {
        setPasswordError(false);
        setPasswordErrorMessage("");
      }
    }
    return isValid;
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{ paddingTop: "2vh" }}
    >
      <Container maxWidth="sm">
        <Card variant="outlined" sx={{ padding: "1vh" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
            >
              Reset password
            </Typography>
            {resetMessage && (
              <Alert severity={resetMessage.error ? "error" : "success"}>
                {resetMessage?.message}
              </Alert>
            )}
          </Stack>
          <Box
            component="form"
            onSubmit={handleReset}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <FormControl>
              <FormLabel htmlFor="new_password">Password</FormLabel>
              <TextField
                required
                fullWidth
                name="new_password"
                placeholder="Password"
                type="password"
                id="new_password"
                autoComplete="new-password"
                variant="outlined"
                error={passwordError}
                helperText={passwordErrorMessage}
                color={passwordError ? "error" : "primary"}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="re_new_password">Retype Password</FormLabel>
              <TextField
                required
                fullWidth
                name="re_new_password"
                placeholder="Retype Password"
                type="password"
                id="re_new_password"
                autoComplete="new-password"
                variant="outlined"
                error={passwordError}
                helperText={passwordErrorMessage}
                color={passwordError ? "error" : "primary"}
              />
            </FormControl>
            <Button type="submit" fullWidth variant="contained">
              Reset
            </Button>
          </Box>
        </Card>
      </Container>
    </Stack>
  );
};

export default PasswordResetConfirm;
