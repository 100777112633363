import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Alert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Fab from "@mui/material/Fab";
import { BlogContext } from "../../contexts/BlogContext.js";
import { AuthContext } from "../../contexts/AuthContext.js";
import config from "../../config/config.json";

const BACKEND_DOMAIN = config.site.auth.urls.backend;

const POST_URL = `${BACKEND_DOMAIN}/${config.site.blog.urls.posts}`;

function PostList() {
  const { isAuth, user, loading } = useContext(AuthContext);
  const { selectedTags } = useContext(BlogContext);
  const [posts, setPosts] = useState([]);
  const [postLoading, setPostLoading] = useState(true);
  const [message, setMessage] = useState();
  const [pageLimit, setPageLimit] = useState(config.site.blog.pages.limit);
  const [pageOffset, setPageOffset] = useState(0);
  const [postCount, setPostCount] = useState(0);
  const [search, setSearch] = useState("");

  const onPageChange = (event) => {
    const offset = pageLimit * (parseInt(event.target.textContent) - 1);
    setPageOffset(offset);
    window.scroll(0, 0);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const pageParams = `limit=${pageLimit}&offset=${pageOffset}`;

  const loadPosts = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get(
        `${POST_URL}?${pageParams}${selectedTags.length ? "&tags=" + selectedTags.join() : ""}${isAuth && user.is_admin ? "" : "&status=PB"}${search.length ? "&search=" + search : ""}`,
        config,
      );
      const data = response.data;
      setPostCount(data.count);
      setPosts(response.data.results);
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
      setPostLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) loadPosts();
  }, [pageOffset, loading]);

  useEffect(() => {
    if (pageOffset === 0) {
      loadPosts();
    } else {
      setPageOffset(0);
    }
  }, [selectedTags, search]);

  if (postLoading || loading) return <>Loading</>;

  const PostList = posts?.map(
    ({
      id,
      slug,
      image,
      title,
      description,
      first_name,
      last_name,
      email,
      publish,
      uimage,
      status,
    }) => (
      <Card key={id} sx={{ margin: "0.3rem", padding: "1vh" }}>
        <Link component={NavLink} to={`/post/${slug}`}>
          <CardMedia
            component="img"
            image={image || "/no_image.png"}
            title={slug}
            sx={{ maxWidth: "100%", height: "40vh" }}
          />
        </Link>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <Link component={NavLink} to={`/post/${slug}`}>
              {title}
            </Link>
          </Typography>
          <Typography>{description}</Typography>
        </CardContent>
        <CardHeader
          avatar={<Avatar alt={`${first_name} ${last_name}`} src={uimage} />}
          title={`${first_name} ${last_name} ${isAuth ? email : ""}`}
          subheader={publish}
        />
        {isAuth && user?.is_admin ? (
          <CardActions>
            <Alert severity={status !== "PB" ? "error" : "success"}>
              {status !== "PB" ? "Not " : ""}Publushed
            </Alert>
            <NavLink to={`/editpost/${slug}`}>
              <IconButton aria-label="edit">
                <EditIcon />
              </IconButton>
            </NavLink>
            <NavLink to={`/deletepost/${slug}`}>
              <IconButton aria-label="delete">
                <DeleteForeverIcon />
              </IconButton>
            </NavLink>
          </CardActions>
        ) : (
          ""
        )}
      </Card>
    ),
  );

  return (
    <Box sx={{ flexGrow: 1, margin: "1rem" }}>
      <Stack spacing={2}>
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "1vh" }}
        >
          <Fab
            size="small"
            color="primary"
            aria-label="search"
            sx={{ marginRight: "1vw" }}
          >
            <SearchIcon />
          </Fab>
          <TextField
            fullWidth
            label="Search posts"
            type="search"
            variant="standard"
            onChange={handleChange}
          />
        </Box>
        {PostList}
      </Stack>
      <Pagination
        count={Math.ceil(postCount / pageLimit)}
        onChange={onPageChange}
        page={parseInt(pageOffset / pageLimit) + 1}
      />
    </Box>
  );
}

export default PostList;
