import { useContext, useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import axios from "axios";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import Alert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import CardActions from "@mui/material/CardActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { MuiMarkdown, getOverrides } from "mui-markdown";
import { Highlight, themes } from "prism-react-renderer";
import { AuthContext } from "../contexts/AuthContext.js";
import config from "../config/config.json";

const BACKEND_DOMAIN = config.site.auth.urls.backend;

const POSTDETAIL_URL = `${BACKEND_DOMAIN}/${config.site.blog.urls.postdetail}`;
const COMMENT_URL = `${BACKEND_DOMAIN}/${config.site.blog.urls.comment}`;

const PostDetail = () => {
  const { isAuth, user, loading, auth } = useContext(AuthContext);
  const [post, setPost] = useState({});
  const [comment, setComment] = useState([]);
  const [editComment, setEditComment] = useState({});
  const [commentBody, setCommentBody] = useState("");
  const [localLoading, setLocalLoading] = useState(true);
  const [loadingComment, setLoadingComment] = useState(true);
  const [reloadComments, setReloadComments] = useState(false);
  const [message, setMessage] = useState();
  const [messageUploadComment, setMessageUploadComment] = useState();
  const [edit, setEdit] = useState(false);
  const [activeComment, setActiveComment] = useState(false);
  const { slug } = useParams();

  const loadPost = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get(`${POSTDETAIL_URL}${slug}/`, config);
      setPost(response.data);
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
      setLocalLoading(false);
    }
  };

  const loadComments = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get(
        `${COMMENT_URL}?post=${post.id}`,
        config,
      );
      setComment(response.data);
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
      setLoadingComment(false);
    }
  };

  const commentUpload = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.access}`,
      },
    };
    try {
      const response = edit
        ? await axios.put(`${COMMENT_URL}${editComment.id}/`, data, config)
        : await axios.post(COMMENT_URL, data, config);
      setMessage({ error: false, message: response });
      setMessageUploadComment({
        error: false,
        message: edit ? "Updated" : "Created",
      });
      setCommentBody("");
      setReloadComments(true);
    } catch (err) {
      setMessage({ error: true, message: err });
      setMessageUploadComment({ error: true, message: "Error!" });
    } finally {
      setLocalLoading(false);
      setEdit(false);
    }
  };

  const loadComment = async (id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get(`${COMMENT_URL}${id}/`, config);
      setEditComment(response.data);
      setActiveComment(response.data.active);
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
    }
  };

  const deleteComment = async (id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.access}`,
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.delete(`${COMMENT_URL}${id}/`, config);
      setReloadComments(true);
    } catch (err) {
      setMessage({ error: true, message: err });
    } finally {
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append("author", user.id);
    data.append("post", post.id);
    if (data.get("active")) {
      data.set("active", activeComment);
    } else {
      data.append("active", activeComment);
    }
    const formData = Object.fromEntries(data);
    console.log(formData);
    setMessageUploadComment();
    commentUpload(data);
  };

  useEffect(() => {
    loadPost();
  }, []);

  useEffect(() => {
    if (post?.id) {
      loadComments();
      setReloadComments(false);
    }
  }, [post, reloadComments]);

  useEffect(() => {
    if (editComment.body) setCommentBody(editComment.body);
  }, [editComment]);

  if (localLoading) return <>Loading</>;

  const commentList =
    loadingComment ||
    comment?.map(
      ({
        id,
        first_name,
        last_name,
        email,
        uimage,
        body,
        updated,
        active,
        author,
      }) => {
        if (active || author === user.id) {
          return (
            <Card key={id} sx={{ margin: "0.3rem", padding: "1vh" }}>
              <CardHeader
                avatar={
                  <Avatar alt={`${first_name} ${last_name}`} src={uimage} />
                }
                title={`${first_name} ${last_name} ${isAuth ? email : ""}`}
                subheader={updated}
              />
              <CardContent>
                <Typography>{body}</Typography>
              </CardContent>
              {(isAuth && user?.is_admin) || user.id === author ? (
                <CardActions>
                  <Alert severity={!active ? "error" : "success"}>
                    {!active ? "Not " : ""}Actived
                  </Alert>
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      setEdit(true);
                      loadComment(id);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      deleteComment(id);
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </CardActions>
              ) : (
                ""
              )}
            </Card>
          );
        }
      },
    );

  return (
    <Container maxWidth="xl">
      <Box>
        <Stack sx={{ paddingTop: "2vh" }}>
          <Card sx={{ margin: "0.3rem", padding: "1vh" }}>
            <CardHeader
              avatar={
                <Avatar
                  alt={`${post.first_name} ${post.last_name}`}
                  src={post.uimage}
                />
              }
              title={`${post.first_name} ${post.last_name} ${isAuth ? post.email : ""}`}
              subheader={post.publish}
            />
            {isAuth && user?.is_admin ? (
              <CardActions>
                <Alert severity={post.status !== "PB" ? "error" : "success"}>
                  {post.status !== "PB" ? "Not " : ""}Publushed
                </Alert>
                <NavLink to={`/editpost/${post.slug}`}>
                  <IconButton aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </NavLink>
                <NavLink to={`/deletepost/${post.slug}`}>
                  <IconButton aria-label="delete">
                    <DeleteForeverIcon />
                  </IconButton>
                </NavLink>
              </CardActions>
            ) : (
              ""
            )}
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {post.title}
              </Typography>
              <Typography>{post.description}</Typography>
            </CardContent>
            <CardMedia
              component="img"
              image={post.image || "/no_image.png"}
              title={post.slug}
              sx={{ maxWidth: "100%", height: "50vh" }}
            />
            <CardContent>
              <MuiMarkdown
                overrides={{
                  ...getOverrides({
                    Highlight,
                    themes,
                    theme: themes.github,
                  }),
                }}
              >
                {post.body}
              </MuiMarkdown>
            </CardContent>
          </Card>
          <Container maxWidth="md">
            <>
              <Box>
                <Typography variant="h5" component="div">
                  Comments:
                </Typography>
              </Box>
              {loadingComment || (
                <Stack sx={{ margin: "1rem" }}>
                  <Stack spacing={2}>{commentList}</Stack>
                </Stack>
              )}
            </>
          </Container>
          <Container maxWidth="md">
            <Box sx={{ margin: "1rem" }}>
              <Stack spacing={2}>
                <Typography variant="h7" component="div">
                  Comments are currently being pre-moderated. If you do not see
                  your comments, it means they have not yet been approved.
                </Typography>
                <Card variant="outlined" sx={{ padding: "1vh" }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      component="h1"
                      variant="h4"
                      sx={{ fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
                    >
                      {edit ? "Edit " : "Create New "}Comment
                    </Typography>
                    {messageUploadComment && (
                      <Alert
                        severity={
                          messageUploadComment.error ? "error" : "success"
                        }
                      >
                        {messageUploadComment?.message}
                      </Alert>
                    )}
                  </Stack>
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: 2,
                    }}
                  >
                    <FormControl>
                      <FormLabel htmlFor="body">Comment</FormLabel>
                      <TextField
                        id="body"
                        type="text"
                        name="body"
                        placeholder="Your comment"
                        autoComplete="body"
                        required
                        multiline
                        minRows={2}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        sx={{ ariaLabel: "comment" }}
                        onChange={(e) => setCommentBody(e.target.value)}
                        value={commentBody}
                      />
                    </FormControl>
                    {user?.is_admin ? (
                      <FormControl>
                        <FormControlLabel
                          control={<Switch />}
                          label="Active"
                          id="active"
                          name="active"
                          onChange={(event) =>
                            setActiveComment(event.target.checked)
                          }
                          checked={activeComment}
                        />
                      </FormControl>
                    ) : (
                      ""
                    )}
                    {!isAuth ? (
                      <Alert severity="error">
                        You must login to leave comments!
                      </Alert>
                    ) : (
                      ""
                    )}
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      loading={localLoading}
                      disabled={!isAuth}
                    >
                      {edit ? "Edit " : "Create New "}Comment
                    </LoadingButton>
                  </Box>
                </Card>
              </Stack>
            </Box>
          </Container>
        </Stack>
      </Box>
    </Container>
  );
};

export default PostDetail;
